import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user.model';
import { CommunicationService } from 'src/app/services/communication.service';
import { LocalStorageService } from 'src/app/services/local-storage.services';
import { ProjectService } from 'src/app/services/project.service';
import { Share } from 'src/app/models/share.model';
import { Project } from 'src/app/models/project.model';
declare var window;
declare var $;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Output() showCalendar = new EventEmitter<boolean>();
  @Input() selectedProject:Project;
  @Output() getShare = new EventEmitter<any>();
  @Input() shares: Share[];
  constructor(private userService:UserService,private communicationService:CommunicationService,private localStorageService:LocalStorageService,
    private projectService:ProjectService) { }
  
  user:User;
  shareObj:any={permission:'read'};
  emailPattern=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  showShared=false;
  ngOnInit() {
    this.getLoggedInUser();
  }

  onShowCalendar() {
    this.showCalendar.emit(true);
  }

  login(){
    console.log("login");
    window.location.href='/api/google/login'
  }
  logout(){
    window.location.href='/api/google/logout'
  }
  getLoggedInUser(){
    this.userService.loggedInUser().subscribe((res:User)=>{
      if(res==null){
        this.userService.setUser(undefined);
        this.getDetailsFromLocalStorage();
        this.communicationService.announceUser('done')
      }else{
        res.anonymous=false;
        this.userService.setUser(res);
        console.log(this.isProjectInLocalStorage())
        if(this.isProjectInLocalStorage()){
          this.saveProjectData(()=>{
            this.localStorageService.removeItem()
            this.communicationService.announceUser('done')
          })
        }else{
          this.communicationService.announceUser('done')
        }
        
        
      }
      this.user=this.userService.getUser();
    },err=>{
      console.log("logged in user error");
      this.userService.setUser(undefined);
      this.getDetailsFromLocalStorage();
      this.communicationService.announceUser('done')
    })
  }

  saveProjectData(cb){
    let obj:any=this.localStorageService.getItems();
    obj.projects[0].created_by=this.userService.getUser().id;
    obj.projects[0].updated_by=this.userService.getUser().id;

    this.saveProject(obj.projects[0],(project)=>{
      obj.columns.forEach(col=>{
        col.created_by=this.userService.getUser().id;
        col.updated_by=this.userService.getUser().id;
        col.project_id=project.id;
      })
      this.saveColumns(obj.columns,(columns)=>{
        obj.rows.forEach(row=>{
          row.created_by=this.userService.getUser().id;
          row.updated_by=this.userService.getUser().id;
          row.project_id=project.id;
        })
        this.saveRows(obj.rows,(row)=>{
          obj.cells.forEach(cell=>{
            cell.created_by=this.userService.getUser().id;
            cell.updated_by=this.userService.getUser().id;
            cell.project_id=project.id;
          })
          this.projectService.populatePositions(obj);
          this.saveCells(obj.cells,()=>{
            cb();
          })
        })
      });
    })
    
  }

  saveProject(projects,cb?){
    console.log(projects)

    this.projectService.addProject(projects)
    .subscribe(res=>{
      if(cb){
        cb(res);
      }
    })
  }

  saveColumns(columns,cb?){
    this.projectService.addColumns(columns)
    .subscribe(res=>{
      if(cb){
        cb();
      }
    })
  }

  saveRows(rows,cb?){
    this.projectService.addRows(rows)
    .subscribe(res=>{
      if(cb){
        cb();
      }
    })
  }

  saveCells(cells,cb?){
    this.projectService.updateCells(cells)
    .subscribe(res=>{
      if(cb){
        cb();
      }
    })
  }

  isProjectInLocalStorage(){
    console.log(localStorage.getItem('__dex_sheet__'));
    if(localStorage.getItem('__dex_sheet__') != undefined && localStorage.getItem('__dex_sheet__') != 'undefined'){
      return true;
    }
    return false;
  }

  getDetailsFromLocalStorage(){
    if(localStorage.getItem('__dex_sheet__') == undefined || localStorage.getItem('__dex_sheet__') == 'undefined'){
       let obj = this.localStorageService.createFirstProject();
       let mainObject = {user:obj.user,projects:[obj.project],rows:obj.rows,columns:obj.columns,cells:obj.cells}
       this.userService.setUser(obj.user);
       console.log("get user "+obj.user);
       localStorage.setItem('__dex_sheet__',JSON.stringify(mainObject));
    }else{
      let obj:any = this.localStorageService.getItems();
      this.userService.setUser(obj.user);
    }
  }

  showShareModal(){
    
  }

  share(){
    let share:Share = new Share();
    share.email=this.shareObj.email;
    share.project_id=this.selectedProject.id;
    share.notes=this.shareObj.notes;
    share.permission=this.shareObj.permission;
    let shares:Share[]=[share]
    this.projectService.addShare(shares,this.userService.getUser().anonymous)
    .subscribe(res=>{
      this.shareObj={permission:'read'};
      this.getShare.emit();
      this.makeShareVisible();
    },err=>{

    })

  }

  makeShareVisible(){
    this.showShared=true;
    $("#shareModalBody").animate({ scrollTop: $($('#showSharedAnchor').attr("href")).offset().top +$($('#showSharedAnchor').attr("href")).height() }, 500);
  }

  

  cancelShare(){
    this.shareObj={permission:'read'};
    this.closeShareModal();
  }

  closeShareModal(){
    $(function () {
      $('#share_modal').modal('toggle');
    });
  }

  deleteShare(share:Share){
    this.projectService.deleteShare(share.id)
    .subscribe((res)=>{
      this.getShare.emit();
    },err=>{
     
    })
  }



  

}
