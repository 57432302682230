import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user.model';
import { Project } from '../models/project.model';
import { Cell } from '../models/cell.model';
import { Row } from '../models/row.model';
import { Column } from '../models/column.model';
import { UserService } from './user.service';
import { LocalStorageService } from './local-storage.services';
import { Observable } from 'rxjs';
import { Share } from '../models/share.model';

@Injectable({
    providedIn: 'root',
})
export class ProjectService {
   
    constructor(
        private http: HttpClient,private userService:UserService,private localStorageService:LocalStorageService) {

    }

   
    getProjects(user_id,anonymous=false){
        if(anonymous){
            const observable = new Observable(observer => {
                let obj:any = this.localStorageService.getItems();
                observer.next(obj.projects.filter(project=>{
                     
                    return project.created_by===user_id
                }));
                 
             
         });
           return observable;
        }
        return this.http.get('api/projects?user_id='+user_id)
    }

    addProject(project:Project,anonymous=false){
        if(anonymous){
            return;
        }
        return this.http.post('api/project',project)
    }

    deleteProject(project_id:number,anonymous=false){
        if(anonymous){
            const observable = new Observable(observer => {
                let obj:any = this.localStorageService.getItems();
                let project = obj.projects[0]
                obj.projects=[];
                this.localStorageService.saveItem(obj);
                observer.next(obj.projects[0]);
         });
           return observable;
        }
        return this.http.delete('api/project/'+project_id)
    }

    updateProject(project:Project,anonymous=false){
        if(anonymous){
            const observable = new Observable(observer => {
                let obj:any = this.localStorageService.getItems();
                obj.projects[0].name=project.name;
                this.localStorageService.saveItem(obj);
                observer.next(obj.projects[0]);
         });
           return observable;
        }
        return this.http.put('api/project/'+project.id,project)
    }

    getColumns(project_id,anonymous=false){
        if(anonymous){
            const observable = new Observable(observer => {
                let obj:any = this.localStorageService.getItems();
                let columns = obj.columns.filter(column=>{
                     
                    return column.project_id===project_id
                })
                columns.sort((a,b)=>{
                    return a.position<b.position?-1:1;
                })
                observer.next(columns);
                 
             
         });
           return observable;
        }
        return this.http.get('api/columns?project_id='+project_id)
    }
    addColumn(col:Column,anonymous=false){
        if(anonymous){
            const observable = new Observable(observer => {
                let obj:any = this.localStorageService.getItems();
                col.id = this.getId(obj.columns);
                obj.columns.push(col);
                this.localStorageService.saveItem(obj);
                this.shiftColumns(col,1); 
                this.addColumnCells(col);
                observer.next(obj.columns);
         });
           return observable;
        }
        return this.http.post('api/column',col)
    }

    addColumns(cols:Column[]){
        return this.http.post('api/columns',cols)
    }

    getRows(project_id,anonymous=false){
                if(anonymous){
                    const observable = new Observable(observer => {
                        let obj:any = this.localStorageService.getItems();
                        let rows = obj.rows.filter(row=>{
                             
                            return row.project_id===project_id
                        })
                        rows.sort((a,b)=>{
                            a.position<b.position?-1:1;
                        })
                        observer.next(rows);
                         
                     
                 });
                   return observable;
        }
        return this.http.get('api/rows?project_id='+project_id)
    }

    addRow(row:Row,anonymous=false){
        if(anonymous){
            const observable = new Observable(observer => {
                let obj:any = this.localStorageService.getItems();
                row.id = this.getId(obj.rows);
                obj.rows.push(row);
                this.localStorageService.saveItem(obj);
                this.shiftRows(row,1);
                this.addRowCells(row);
                observer.next(obj.rows);
                 
             
         });
         return observable;
        }
        return this.http.post('api/row',row)
    }

    addRows(rows:Row){
        return this.http.post('api/rows',rows)
    }

    deleteRows(rows:number[],anonymous=false){
        if(anonymous){
            const observable = new Observable(observer => {
                let obj:any = this.localStorageService.getItems();
                let mRows=[];
                let deleteRows=[];
                obj.rows.forEach(row=>{
                    if(rows.indexOf(row.id)!=-1){
                        this.shiftRows(row,-1);
                        deleteRows.push(row);
                    }
                });
                deleteRows.forEach(r=>{
                    this.deleteRowCells(r);
                })
                obj= this.localStorageService.getItems();
                obj.rows.forEach(row=>{
                    if(rows.indexOf(row.id)==-1){
                        mRows.push(row);
                    }
                });
                obj.rows=mRows;
                this.localStorageService.saveItem(obj);
                observer.next(obj.rows);
         });
         return observable;
        }
        let queryStr=''
        rows.forEach((r,i)=>{
            queryStr=queryStr+rows[0];
            if(i!==rows.length-1 && rows.length>1){
                queryStr=queryStr+';';
            }
           
        })
        return this.http.delete('api/row/'+queryStr)
    }

    deleteColumns(cols:number[],anonymous=false){
        if(anonymous){
            const observable = new Observable(observer => {
                let obj:any = this.localStorageService.getItems();
                let mCols=[];
                let deletedColumns=[];
                obj.columns.forEach(col=>{
                    if(cols.indexOf(col.id)!=-1){
                        this.shiftColumns(col,-1);
                        deletedColumns.push(col);
                    }
                });
                deletedColumns.forEach(c=>{
                    this.deleteColumnCells(c);
                })
                obj= this.localStorageService.getItems();
                obj.columns.forEach(col=>{
                    if(cols.indexOf(col.id)==-1){
                        mCols.push(col);
                    }
                });
                obj.columns=mCols;
                this.localStorageService.saveItem(obj);
                
                observer.next(obj.columns);
                 
             
         });
         return observable;
        }
        let queryStr=''
        cols.forEach((r,i)=>{
            queryStr=queryStr+cols[0];
            if(i!==cols.length-1 && cols.length>1){
                queryStr=queryStr+';';
            }
           
        })
        return this.http.delete('api/column/'+queryStr)
    }

    getCells(project_id,anonymous=false){
        if(anonymous){
            const observable = new Observable(observer => {
                let obj:any = this.localStorageService.getItems();
                this.populatePositions(obj);
                obj.cells.sort((a,b)=>{
                    if(a.row_position<b.row_position){
                        return -1;
                    }
                    if(a.row_position===b.row_position){
                        if(a.col_position<b.col_position){
                            return -1;
                        }else{
                            return 1;
                        }
                    }else{
                        return 1;
                    }
                })
                observer.next(obj.cells.filter(cell=>{
                    return cell.project_id===project_id
                }));
                 
             
         });
           return observable;
        }
        return this.http.get('api/cells?project_id='+project_id)
    }

    populatePositions(obj){
        let rowObj={},colObj={};
                obj.columns.forEach((col,i)=>{
                    colObj[col.id]=col;
                })
                obj.rows.forEach((row,i)=>{
                    rowObj[row.id]=row;
                })
                obj.cells.forEach((cell,i)=>{
                    cell.col_position=colObj[cell.column_id].position;
                    cell.row_position=rowObj[cell.row_id].position;
                })
    }

    updateCell(cell:Cell,anonymous=false){
        if(anonymous){
            const observable = new Observable(observer => {
                let obj:any = this.localStorageService.getItems();
                obj.cells.forEach((c,i)=>{
                    if(cell.id===c.id){
                        obj.cells[i]=cell;
                    }
                })
                this.localStorageService.saveItem(obj);
                observer.next(cell);
                 
             
         });
           return observable;
        }
        return this.http.put('api/cell/'+cell.id,cell)
    }

    updateCells(cells:Cell[]){
        return this.http.put('api/cells',cells)
    }

    shiftRows(row,index){
         let obj:any = this.localStorageService.getItems();
         obj.rows.forEach((r,i)=>{
             if(r.id!==row.id && r.position>=row.position){
                 obj.rows[i].position +=index;
             }
         })
         this.localStorageService.saveItem(obj);
    }

    shiftColumns(col,index){
         let obj:any = this.localStorageService.getItems();
         obj.columns.forEach((c,i)=>{
             if(c.id!==col.id && c.position>=col.position){
                 obj.columns[i].position +=index;
             }
         })
         this.localStorageService.saveItem(obj);
    }

    deleteRowCells(row){
        let obj:any = this.localStorageService.getItems();
        let cells=[];
        obj.cells.forEach((cell,i)=>{
            if(cell.row_id!==row.id){
                cells.push(cell)
            }
        })
        obj.cells=cells;
        this.localStorageService.saveItem(obj);
   }

   deleteColumnCells(col){
    let obj:any = this.localStorageService.getItems();
    let cells=[];
    obj.cells.forEach((cell,i)=>{
        if(cell.column_id!==col.id){
            cells.push(cell)
        }
    })
    obj.cells=cells;
    this.localStorageService.saveItem(obj);
}
    


getId(items:any,index=0){
    let id= Math.max(...items.map(item=>{
        return item.id;
    }))
    return id+1+index;
}

addColumnCells(col){
  let obj:any = this.localStorageService.getItems();
  let cells=[];
  obj.rows.forEach(row=>{
      let id = this.getId(obj.cells,cells.length);
      cells.push({id:id,col_position:col.position,row_position:row.position,col_name:col.name,column_id:col.id,row_id:row.id,data:null})
  })

  obj.cells.push(...cells);
  this.localStorageService.saveItem(obj);
}

addRowCells(row){
  let obj:any = this.localStorageService.getItems();
  let cells=[];
  obj.columns.forEach(col=>{
      let id = this.getId(obj.cells,cells.length);
      cells.push({id:id,col_position:col.position,row_position:row.position,col_name:col.name,column_id:col.id,row_id:row.id,data:null})
  })

  obj.cells.push(...cells);
  this.localStorageService.saveItem(obj);
}

addShare(share:Share[],anonymous=false){
    if(anonymous){
        const observable = new Observable(observer => {
            observer.next([]);
     });
       return observable;
    }
    return this.http.post('api/share',share);
}

getShare(project_id:number,anonymous=false){
    if(anonymous){
        const observable = new Observable(observer => {
            observer.next([]);
     });
       return observable;
    }
    return this.http.get('api/share?project_id='+project_id);
}

deleteShare(share_id:number){
    return this.http.delete('api/share/'+share_id);
}


}