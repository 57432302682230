
export  class User {

    constructor(id,created_at,anonymous){
        this.id=id;
        this.created_at=created_at;
        this.anonymous=anonymous;
    }

    id:number;
    email:string;
    name:string;
    picture:string;
    picture2:string;
    username:string;
    token:string;
    created_at:string;
    anonymous?:boolean
}