import { Component, OnInit, ViewChild, Input } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Event } from 'src/app/models/event.model';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styles: []
})
export class CalendarComponent implements OnInit {

  calendarPlugins = [dayGridPlugin, interactionPlugin];
  @Input() events: Event[];
  @Input('cellObj') cellObj;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
    
  constructor() {
    this.events = []
  }

  ngOnInit() {
    this.createEventObj();
  }

  createEventObj(){
    this.events = [];
    console.log("here>>>>>>")
    var obj = { title: '', start: '', id: ''};
    this.cellObj.map((item1) => {
      obj = { title: '', start: '', id: ''}
      item1.map(item2 => {
        if (item2 !== undefined) {
          if (item2['col_name'] == 'End Date') {
            obj['start'] = item2['data']
          }
          if (item2['col_name'] == 'Task Name') {
            obj['title'] = item2['data'];
            obj['id'] = item2['row_id']
          }
        }
      })
      if (obj) {
        if(obj['title'] && obj['start']){
          this.events.push(obj);
        }
            
      }

    })
    setTimeout(()=>{this.updateEvent(this.events, ['start', 'title'])}, 1000)    
  }
 

  /* addEvent(){
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.addEvent({ // this object will be "parsed" into an Event Object
    title: 'The Title', // a property!
    start: '2019-04-01',
    id:'1' // a property! ** see important note below about 'end' **
  })
  }

  addEvents(){
    let calendarApi = this.getCalendarApi();
    this.events.forEach((e)=>{
      calendarApi.addEvent(e);
    })
    
  } */

  removeEvent(id: string) {
    let events = this.getCalendarEvents();
    events.forEach((e) => {
      if (e.id == id) {
        e.remove();
      }
    })

  }

  updateEvent(eventObj: any, propsToChange: string[]) {
    let event;
    console.log(JSON.stringify(eventObj))
    eventObj.map((e)=>{
      event = this.getEventById(e.id);
      if(event){
        if (propsToChange.indexOf('start') != -1) {
          event.setStart(e.start);
        }
        if (propsToChange.indexOf('end') != -1) {
          event.setEnd(e.end);
        }
        if (propsToChange.indexOf('title') != -1) {
          event.setProp('title', e.title);
        }
      }
      
    })

  }

  getEventById(id: string) {
    let calendarApi = this.getCalendarApi();
    return calendarApi.getEventById(id);
  }

  getCalendarEvents() {
    let calendarApi = this.getCalendarApi();
    return calendarApi.getEvents();
  }

  getCalendarApi() {
    return this.calendarComponent.getApi();
  }



}
