
export  class Column {
    id:number;
    name:string;
    position:number;
    created_by:number;
    created_at:Date;
    updated_at:Date;
    updated_by:number;
    project_id:number;
    column_type:string;
}