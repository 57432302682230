import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2'
import { Project } from 'src/app/models/project.model';
import { UserService } from '../../services/user.service'
import { User } from 'src/app/models/user.model';
import { ProjectService } from 'src/app/services/project.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { Column } from 'src/app/models/column.model';
import { Row } from 'src/app/models/row.model';
import { Cell } from 'src/app/models/cell.model';
import { SheetComponent } from 'src/app/components/sheet/sheet.component';
import { SplitComponent } from 'angular-split';
import { CalendarComponent } from 'src/app/components/calendar/calendar.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { Share } from 'src/app/models/share.model';

declare var $;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  showCalendar = false;
  newProject: Project = new Project();
  showAddNewProject = false;
  projects: Project[];
  projectRename: Project;
  sProjectRename: Project;
  selectedProject: Project;
  projectColumns: Column[];
  projectRows: Row[];
  projectCells: Cell[];
  fontSizes: any = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];
  selectedFont:any = 12;
  cellObj:any;
  sheetProgress:boolean;
  shares:Share[];
  @ViewChild('app_sheet')
  private appSheetComponent: SheetComponent;
  @ViewChild(SplitComponent) splitEl: SplitComponent;

  @ViewChild('cal') 
  private appCalendarComponent: CalendarComponent;

  @ViewChild('app_header')
  private appHeader: HeaderComponent;
  
  constructor(public userService: UserService, private projectService: ProjectService, private communicationService: CommunicationService) {
    this.communicationService.userAnnounced$.subscribe(res => {
      this.getObjects();
    })
    this.communicationService.cellUpdated$.subscribe(msg=>{ 
      this.initCellObject(); 
    })
  }

  ngOnInit() {
     // this is used to resize the excel-sheet and its scrollbar
    // this.splitEl.dragProgress$.subscribe(x => this.adjust_excel_scrollbar());
    $(document).ready(function () {
      $(".sub-head-btn").click(function (){
        $(".sub-head-btn").removeClass('active');
        $(this).addClass('active');
      })
    })
  }

  getObjects() {
    this.getProjects(() => {
      this.showSheetProgress();
      this.getColumns(() => {
        this.getRows(() => {
          this.getCells(()=>{
            this.hideSheetProgress();
            this.initCellObject();
          });
        })
      })
    });
  }


  initCellObject(){
    let cellObj=[]; 
    for(var i=0;i<this.projectRows.length;i++){ 
      if(!cellObj[i]){ 
        cellObj[i]=[]; 
      } 
      for(var j=0;j<this.projectColumns.length;j++){ 
        cellObj[i][j]=undefined; 
      } 
    } 
    this.projectCells.forEach((cell,index)=>{ 
      cellObj[cell.row_position][cell.col_position]=cell; 
    }) 
    this.cellObj = cellObj; 
    
  }

  getProjects(cb?) {
    this.projectService.getProjects(this.userService.getUser().id,this.userService.getUser().anonymous)
    .subscribe((res:Project[])=>{
      this.projects=res;
      this.setSelectedProject();
      this.appHeader.selectedProject=this.selectedProject;
      this.getShare();
      this.higlightSelected();
      if(cb){
        cb();
      }
    })
     
  }

  getShare(){
    this.projectService.getShare(this.selectedProject.id,this.userService.getUser().anonymous)
    .subscribe((res:Share[])=>{
      this.shares=res;
      this.appHeader.shares=res;
    },err=>{

    })
  }

  setSelectedProject(){
    this.selectedProject=undefined;
    if(localStorage.getItem('selected_project_id') != undefined && localStorage.getItem('selected_project_id') != 'undefined'){
      this.projects.forEach((project,i)=>{
        if(project.id == JSON.parse(localStorage.getItem('selected_project_id'))){
          this.selectedProject=project;
        }
    })
    }
    if(!this.selectedProject && this.projects.length){
      this.selectedProject=this.projects[0];
    }
  }

  higlightSelected(){
    this.projects.forEach((project,i)=>{
      if(project.id == this.selectedProject.id){
        project.selected=true;
      }else{
        project.selected=false;
      }
  })
  }

  toggleSelectedProject(project){
    this.selectedProject=project;
    this.appHeader.selectedProject=this.selectedProject;
    this.getShare();
    localStorage.setItem('selected_project_id',project.id);
    this.higlightSelected();
    this.getColumns(()=>{
      this.getRows(()=>{
        this.getCells(()=>{
          this.appSheetComponent.columns=this.projectColumns;
          this.appSheetComponent.rows=this.projectRows;
          this.appSheetComponent.cells=this.projectCells;
          $('#dex-sheet').empty();
          this.appSheetComponent.onProjectChange();
        });
      })
    })
  }



  getColumns(cb?){
    this.projectService.getColumns(this.selectedProject.id,this.userService.getUser().anonymous)
      .subscribe((res: Column[]) => {
        this.projectColumns = res;
        if (cb) {
          cb();
        }

      })
  }

  getRows(cb?) {
    this.projectService.getRows(this.selectedProject.id,this.userService.getUser().anonymous)
      .subscribe((res: Row[]) => {
        this.projectRows = res;
        if (cb) {
          cb();
        }
      })
  }

  getCells(cb?) {
    this.projectService.getCells(this.selectedProject.id,this.userService.getUser().anonymous)
      .subscribe((res: Cell[]) => {
        this.projectCells = res;
        if(this.appCalendarComponent){
          this.appCalendarComponent.createEventObj();
        }  
        if (cb) {
          cb();
        }
      })
  }

  toggleShowCalendar(){
    this.showCalendar=!this.showCalendar;
    // setTimeout(()=>{
    //   this.adjust_excel_scrollbar();
    // },500)
  }

  adjust_excel_scrollbar(){
     //show horizontal scrollbar
     let widnow_width = $(window).width();
     let calendar_width = $('.fc-view-container').width();
     let sheet_width = $('#dex-sheet').width();
     let width_diffrence = (widnow_width - calendar_width);
     width_diffrence = width_diffrence;
     $('#dex-sheet').css({'overflow-x':'scroll','max-width':width_diffrence});

    //show vertical scrollbar
    let widnow_height = $(window).height();
    let header_height_1 = $('#kt_subheader_1').height();
    let header_height_2 = $('#kt_subheader_2').height();
    let sheet_height = $('#dex-sheet').height();
    let diffrence = (widnow_height - (header_height_1 + header_height_2));
    diffrence = diffrence - 90; //to set default height
    diffrence = diffrence - 20; // subtract 20 more to show scrollbar proper
    $('.jexcel-content').css({'overflow-y':'scroll','max-height':diffrence});
  }

  onDragEnd(e){
   //this.adjust_excel_scrollbar();
  }

  remove_horizontal_scrollbar(){
    $('#dex-sheet').css({'overflow-x':'hidden','max-width':'100%'});
  }

  showSheetOnly(){
    this.showCalendar=false;
    this.remove_horizontal_scrollbar();
  }

  addNewProject() {
    if (!this.newProject.name || this.newProject.name.trim().length === 0) {
      Swal.fire({
        title: 'Error!',
        text: 'Please enter the project name',
        type: 'error',
        confirmButtonText: 'Ok',
        onClose: () => {
          $('input#new-project').focus();
        }
      })
      return;
    }
    this.addProject();
  }

  addProject() {
    //TODO API call to add project, then in response write below line
    let project = new Project();
    project['name'] = this.newProject.name;
    project['created_by'] = this.userService.getUser().id;
    project['updated_by'] = this.userService.getUser().id;
    this.projectService.addProject(project,this.userService.getUser().anonymous)
      .subscribe((res: Project) => {
        this.projects.push(res);
        this.showAddNewProject = false;
        this.newProject = new Project();
        this.toggleSelectedProject(res);
        this.closeProjectCreateModal();
      })

  }

  deleteProject(project_id) {
    this.projectService.deleteProject(project_id,this.userService.getUser().anonymous)
      .subscribe((res: any) => {
        this.projects.forEach((project, i) => {
          if (res.id === project.id) {
            this.projects.splice(i, 1);
          }
        })
        if(this.projects.length===0){
          $('#dex-sheet').empty();
        }else{
          this.toggleSelectedProject(this.projects[0]);
        }
      })
  }



  showAddNewProjectOption() {
    this.newProject = new Project();

  }

  cancelNewProject() {
    this.newProject = undefined;
  }

  renameProject() {
    this.projectRename.updated_by = this.userService.getUser().id;
    this.projectService.updateProject(this.projectRename,this.userService.getUser().anonymous)
      .subscribe(res => {
        this.projects.forEach((pr, i) => {
          if (pr.id === this.projectRename.id) {
            this.projects[i] = this.projectRename;
          }
        })
        this.closeProjectRenameModal();
      })
  }

  closeProjectRenameModal() {
    $(function () {
      $('#project_rename_modal').modal('toggle');
    });
  }

  closeProjectCreateModal() {
    $(function () {
      $('#project_create_modal').modal('toggle');
    });
  }

  
  initRenameProject(project:Project){
    this.projectRename=Object.assign({},project);
    this.sProjectRename=Object.assign({},project);
  }

  cancelProjectRename() {
    this.projectRename = new Project();
  }

  onRowInsert(row) {
    row.project_id = this.selectedProject.id;
    this.projectService.addRow(row,this.userService.getUser().anonymous)
      .subscribe(res => {
        console.log("row added");
        this.getRows(() => {
          this.getCells(() => {
            console.log("init cell obj");
            this.appSheetComponent.rows = this.projectRows;
            this.appSheetComponent.cells = this.projectCells;
            this.communicationService.announceCellRefresh('done');
          })
        })
      }, err => {

      })
  }

  onColumnInsert(col) {
    col.project_id = this.selectedProject.id;
    this.projectService.addColumn(col,this.userService.getUser().anonymous)
      .subscribe(res => {
        console.log("col added");
        this.getColumns(() => {
          this.getCells(() => {
            this.appSheetComponent.columns = this.projectColumns;
            this.appSheetComponent.cells = this.projectCells;
            this.communicationService.announceCellRefresh('done');
          })
        })
      }, err => {

      })
  }

  onRowsDelete(rows: number[]) {
    this.projectService.deleteRows(rows,this.userService.getUser().anonymous)
      .subscribe(res => {
        this.getRows(() => {
          this.getCells(() => {
            this.appSheetComponent.rows = this.projectRows;
            this.appSheetComponent.cells = this.projectCells;
            this.communicationService.announceCellRefresh('done');
            if(this.appCalendarComponent){
              this.appCalendarComponent.removeEvent(rows.toString());
            }
          })
        })
      }, err => {

      })
  }

  onColumnsDelete(cols: number[]) {
    this.projectService.deleteColumns(cols,this.userService.getUser().anonymous)
      .subscribe(res => {
        this.getColumns(() => {
          this.getCells(() => {
            this.appSheetComponent.columns = this.projectColumns;
            this.appSheetComponent.cells = this.projectCells;
            this.communicationService.announceCellRefresh('done');
          })
        })

      }, err => {

      })
  }

  makeBold() {
    this.appSheetComponent.editStyling('bold')
  }

  makeItalic() {
    this.appSheetComponent.editStyling('italic')
  }

  changeFontSize(){
    this.appSheetComponent.changeFontSize(this.selectedFont);
  }

  setFontSize(font){
    if(font != undefined){
      this.selectedFont = font;
    }
    else{
      this.selectedFont = 12;
    }
    
  }

  showSheetProgress(){
    this.sheetProgress=true;
  }

  hideSheetProgress(){
    this.sheetProgress=false;
  }

 
}
