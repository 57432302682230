import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter, ViewChild } from '@angular/core'; 
import { Column } from 'src/app/models/column.model'; 
import { Row } from 'src/app/models/row.model'; 
import { Cell } from 'src/app/models/cell.model'; 
import { initDomAdapter } from '@angular/platform-browser/src/browser'; 
import { ProjectService } from 'src/app/services/project.service'; 
import { setFirstTemplatePass } from '@angular/core/src/render3/state'; 
import { UserService } from 'src/app/services/user.service'; 
import { CommunicationService } from 'src/app/services/communication.service'; 
import { fakeAsync } from '@angular/core/testing';
import { CalendarComponent } from 'src/app/components/calendar/calendar.component';
import { controlNameBinding } from '@angular/forms/src/directives/reactive_directives/form_control_name';
import { Share } from 'src/app/models/share.model';

declare var $; 
var self; 

@Component({ 
  selector: 'app-sheet', 
  templateUrl: './sheet.component.html', 
  styles: [] 
}) 

export class SheetComponent implements OnInit, AfterContentInit { 
 
  @Input() columns:Column[] 
  @Input() rows:Row[] 
  @Input() cells:Cell[] 
  @Output() getCells = new EventEmitter<boolean>(); 
  @Output() onRowInsert = new EventEmitter<Row>(); 
  @Output() onColumnInsert = new EventEmitter<Column>(); 
  @Output() onRowDelete = new EventEmitter<Row>(); 
  @Output() onColumnDelete = new EventEmitter<Row>(); 
  @Output() changeFont = new EventEmitter();
  @Input('cellObj') cellObj;
  @ViewChild('cal') 
  @Input() shares: Share[];
  private appCalendarComponent: CalendarComponent;

  colTypes:any[]; 
  selectedFont:any = 12;
  dataset: any[] = [ 
    [ 1,'Planning Phase', null,'2018-10-16','2018-10-19','',''], 
    [ 2,'Define Objective', 'Nick','2018-10-16','2018-10-17','Completed','We can move it to done'] 
     
  ]; 
 
  defaultColumns:string[]; 
  statuses=['Completed','Pending','Overdue'] 
  colTypeMapping={string:'text',integer:'number',datetime:'calendar',dropdown:'dropdown'} 
  selectedCells: any[]=[]; 
  selectedCols: number[]=[]; 
  selectedRows: number[]=[]; 
  pSelectedRows: number[]=[]; 
  pSelectedCols: number[]=[]; 
  colName:string;
  changeColName: any;
  constructor(private projectService:ProjectService,private userService:UserService, private communicationService:CommunicationService) { } 
 
  ngOnInit() {     
    /* this.communicationService.cellUpdated$.subscribe(msg=>{ 
      this.initCellObject(); 
    })  */
    self=this; 
    this.init(); 
    this.addData(); 
    this.initCellObject(); 
    this.addSheet();  
    this.addStyling(); 
    //this.set_sheet_height();
  } 
   
  onProjectChange(){
    this.init(); 
    this.addData(); 
    this.initCellObject(); 
    this.addSheet(); 
    this.addStyling(); 
  }

   getStatuses(instance, cell, c, r, source){ 
    return source; 
  } 
 
  getOwnedBy(instance, cell, c, r, source){ 
    return self.shares.map(share=>{
      return share.name;
    }) 
  } 

  ngAfterContentInit(){ 
  } 
 
  init(){ 
    this.defaultColumns=this.columns.map(col=> col.name) 
    this.colTypes = this.columns.map(col=>{ 
      let type:any= {type:this.colTypeMapping[col.column_type]} 
      if(type.type==='calendar'){ 
        type.options={format:'DD/MM/YYYY'}; 
      } 
      if(type.type==='dropdown' && col.name==='Status'){ 
        type.source =this.statuses; 
        type.filter=this.getStatuses 
      } 
      if(type.type==='dropdown' && col.name==='Owned By'){ 
        type.source =[]; 
        type.filter=this.getOwnedBy 
      } 
      return type; 
    }) 
  }


  
  
 
  addData(){ 
    let dataset=[]; 
    for(var i=0;i<this.rows.length;i++){ 
       if(!dataset[i]){ 
         dataset[i]=[] 
       } 
      for(var j=0;j<this.columns.length;j++){ 
        dataset[i][j]=null 
      } 
    } 
    this.cells.forEach((cell,index)=>{ 
      if(cell.data){ 
        dataset[cell.row_position][cell.col_position]=cell.data 
      } 
       
    }) 
    this.dataset=dataset; 
  } 
 
  initCellObject(){ 
    let cellObj=[]; 
    for(var i=0;i<this.rows.length;i++){ 
       if(!cellObj[i]){ 
         cellObj[i]=[]; 
       } 
      for(var j=0;j<this.columns.length;j++){ 
        cellObj[i][j]=undefined; 
      } 
    } 
    this.cells.forEach((cell,index)=>{ 
      cellObj[cell.row_position][cell.col_position]=cell; 
    }) 
    this.cellObj=cellObj; 
  } 
 
  onSelection(obj,start,end){ 
    console.log("len "+$(start).prop('id').split('-')); 
    console.log("selection "+$(end).prop('id').split('-')); 
    $('#bold-button').removeClass('active');
    $('#italic-button').removeClass('active');
    self.pSelectedRows=self.selectedRows; 
    self.pSelectedCols=self.selectedCols; 
    self.selectedRows=[]; 
    self.selectedCells=[]; 
    self.selectedCols=[]; 
    let temp = $(start).prop('id').split('-'); 
    let startArr = [parseInt(temp[0]),parseInt(temp[1])] 
    temp = $(end).prop('id').split('-'); 
    let endArr = [parseInt(temp[0]),parseInt(temp[1])]; 
    let startRow = startArr[1] < endArr[1]?startArr[1]:endArr[1]; 
    let endRow = startArr[1] < endArr[1]?endArr[1]:startArr[1]; 
    let startCol = startArr[0] < endArr[0]?startArr[0]:endArr[0]; 
    let endCol = startArr[0] < endArr[0]?endArr[0]:startArr[0]; 
    for(var j=startCol;j<=endCol;j++){ 
      self.selectedCols.push(j); 
    } 
 
    for(var i=startRow;i<=endRow;i++){ 
      self.selectedRows.push(i); 
      for(var j=startCol;j<=endCol;j++){ 
        self.selectedCells.push([i,j]); 
      } 
    } 

    self.selectedCells.forEach(cell=>{  
      if($('#'+cell[1]+'-'+cell[0]).hasClass('bold')){
        $('#bold-button').addClass('active')
      }
      if($('#'+cell[1]+'-'+cell[0]).hasClass('italic')){
        $('#italic-button').addClass('active')
      }
    })

    self.checkFontSize();
     
  } 

  checkFontSize(){
    var flag = false;
    var font = null;
    self.selectedCells.map((cell) => { 
      this.cellObj.map((item1) => { 
        item1.map(item2 => { 
          if (item2 !== undefined) { 
            if (item2['row_position'] == cell[0] && item2['col_position'] == cell[1]) {
              if(!flag)
                flag = true
                if(item2['meta_data']){
                  font = item2['meta_data']['font_size'];
                  this.changeFont.emit(font);
                }
                
            }
          }
        })
      })
    })
  }
 
  rowInsert(obj){ 
    console.log("new row inserted "+JSON.stringify(self.selectedRows)); 
    let row = new Row(); 
    row.created_by=self.userService.getUser().id; 
    row.updated_by=self.userService.getUser().id; 
    row.position=Math.min(...self.selectedRows); 
    self.onRowInsert.emit(row) 
    //self.set_sheet_height();
     
  } 
 
  columnInsert(obj,colm){ 
    console.log("new column inserted1 "+Math.min(...self.selectedCols)); 
    let col = new Column(); 
    col.created_by=self.userService.getUser().id; 
    col.updated_by=self.userService.getUser().id; 
    col.position=Math.min(...self.selectedCols); 
    col.name=self.colName;
    col.column_type='string'; 
    self.onColumnInsert.emit(col);
    self.colName=undefined; 
  } 
 
  getDefaultColumnName(colIndex){ 
    return String.fromCharCode(65+colIndex); 
  } 
   
 
  onCallInsert(){ 
    
  } 
 
  rowDelete(obj){ 
    let rows=[]; 
    self.rows.forEach(row=>{ 
      if(self.pSelectedRows.indexOf(parseInt(row.position))!=-1){  
        rows.push(row.id) 
      } 
    }) 
    self.onRowDelete.emit(rows) 
  } 
 
  columnDelete(){ 
    console.log("on column delete"); 
    let cols=[]; 
    self.columns.forEach(col=>{ 
      if(self.pSelectedCols.indexOf(parseInt(col.position))!=-1){  
        cols.push(col.id) 
      } 
    }) 
    self.onColumnDelete.emit(cols) 
  } 
 
  update(obj, cel, val){ 
    console.log($(cel).prop('id').split('-')) 
    let id = $(cel).prop('id').split('-') 
    let col = parseInt(id[0]); 
    let row = parseInt(id[1]); 
    // self.initCellObject(); 
    self.cellObj[row][col].data=val; 
    self.cellObj[row][col].updated_by=self.userService.getUser().id; 
    self.projectService.updateCell(self.cellObj[row][col],self.userService.getUser().anonymous) 
    .subscribe(res=>{ 
      console.log("updated cell"); 
      self.getCells.emit(); 
    },err=>{ 
      console.log("error in updating cell"); 
    }) 
  } 
 
   
 
  addSheet(){ 
    $('#dex-sheet').jexcel({ 
      data:this.dataset, 
      tableOverflow:true, 
      csvHeaders:true, 
      tableHeight:'auto', 
      colHeaders: this.defaultColumns, 
      colWidths:this.getWidths(), 
      onchange:this.update, 
      ondeletecolumn:this.columnDelete, 
      onselection:this.onSelection, 
      oninsertrow:this.rowInsert, 
      ondeleterow:this.rowDelete, 
      oninsertcolumn:this.columnInsert, 
      onBeforeColumnInsert:this.onBeforeColumnInsert,
      allowComments:false,
      about:false,
      saveAs:false,
      columnSorting:false,
      renameColumn:false,
      columns: this.colTypes 
  }); 
  } 
 
  onBeforeColumnInsert(cb){
    this.colName=undefined;
    $('#col_name_modal').modal('show');
    self.changeColName=cb;
   
  }

  setColName(){
    this.closeColNameModal();
    console.log(this.changeColName);
    this.changeColName(this.colName);
  }

  cancelColName(){
    this.colName=undefined;
    this.closeColNameModal();
  }

  closeColNameModal() {
    $(function () {
      $('#col_name_modal').modal('toggle');
    });
  }
 
  getWidths(){ 
    console.log($('#calendar-sheet-container').width());
   let width= $('#calendar-sheet-container').width(); 
   width = width-30; // this because first index bydefault takes 30 width by jexcel 
   width = width-20; // this is because we want to show scrollbar 
   return [width*0.35,width*0.10,width*0.10,width*0.10,width*0.10,width*0.25] 
 
  } 
 
  adjustContainer(){ 
    let window_width = $(window).width(); 
    if(window_width > 1024){ 
      $('.kt-fluid').css({'position':'fixed'}); 
    } 
  } 

  set_sheet_height(){
    //get height of header, window and sheet to set sheet's height
    let widnow_height = $(window).height();
    let header_height_1 = $('#kt_subheader_1').height();
    let header_height_2 = $('#kt_subheader_2').height();
    let sheet_height = $('#dex-sheet').height();
    let diffrence = (widnow_height - (header_height_1 + header_height_2));
    diffrence = diffrence - 90;
    if(sheet_height > diffrence){
      $('.jexcel-content').css({'overflow-y':'scroll','max-height':diffrence});
    }

  }
 
  updateStyling(cells) { 
    if (cells.length > 0) { 
      cells.map((cell) => { 
        cell.updated_by = self.userService.getUser().id; 
        self.projectService.updateCell(cell,this.userService.getUser().anonymous) 
          .subscribe(res => { 
            console.log("updated cell"); 
          }, err => { 
            console.log("error in updating cell"); 
          }) 
      }) 
    } 
  } 
  editStyling(style){
    var res = this.decideStyle(style); 
    if (res.flag) { 
      if (res.elements.length > 0) { 
        res.elements.map((item) => { 
          $(item).addClass(style); 
          $('#'+style+'-button').addClass('active');
        }) 
        res.obj.map((ele) => {
          if(ele['meta_data']){
            ele['meta_data'][style] = true; 
          }
          else{
            ele['meta_data'] = {};
            ele['meta_data'][style] = true;
          }           
        }) 
      } 
    } 
    else { 
      if (res.elements.length > 0) { 
        res.elements.map((item) => { 
          $(item).removeClass(style);
          $('#'+style+'-button').removeClass('active'); 
        }) 
        res.obj.map((ele) => { 
          if(ele['meta_data']){
            ele['meta_data'][style] = false; 
          }
          else{
            ele['meta_data'] = {};
            ele['meta_data'][style] = false;
          }  
        }) 
      } 
    } 
    this.updateStyling(res.obj) 
  }

  decideStyle(style){
    var flag = true; 
    var elements = []; 
    var obj = []; 
    self.selectedCells.map((cell) => { 
      this.cellObj.map((item1) => { 
        item1.map(item2 => { 
          if (item2 !== undefined) { 
            if (item2['row_position'] == cell[0] && item2['col_position'] == cell[1]) { 
              elements.push('#' + cell[1] + '-' + cell[0]); 
              obj.push(item2); 
              Object.keys(item2).map((info) => { 
                if (item2[info] && info == 'meta_data') { 
                  if (item2[info][style] == "true" || item2[info][style] == true) { 
                    flag = false; 
                    return { flag: flag, elements: elements, obj: obj }; 
                  } 
                } 
              }) 
            } 
          } 
        }) 
      }) 
    }) 
    return { flag: flag, elements: elements, obj: obj }; 
  }

  addStyling() { 
    this.cellObj.map((item1) => { 
      item1.map(item2 => { 
        if (item2 !== undefined) 
          Object.keys(item2).map((info) => { 
            if (item2[info] && info == 'meta_data') { 
              if (item2[info]['bold'] == "true" || item2[info]['bold'] == true) { 
                var id = item2['col_position'] + '-' + item2['row_position'] 
                $('#' + id).addClass('bold'); 
              } 
              if (item2[info]['italic'] == "true" || item2[info]['italic'] == true) { 
                var id = item2['col_position'] + '-' + item2['row_position'] 
                $('#' + id).addClass('italic'); 
              } 
              if(item2[info]['font_size']){
                var id = item2['col_position'] + '-' + item2['row_position'];
                $('#' + id).css('font-size', item2[info]['font_size']+'px');
              }
            } 
          }) 
      }) 
    }) 
  } 

  changeFontSize(font){
    var elements = []; 
    var obj = []; 
    self.selectedCells.map((cell) => { 
      this.cellObj.map((item1) => { 
        item1.map(item2 => { 
          if (item2 !== undefined) { 
            if (item2['row_position'] == cell[0] && item2['col_position'] == cell[1]) {
              elements.push('#' + cell[1] + '-' + cell[0]); 
              obj.push(item2); 
            }
          }
        })
      })
    })

    if (elements.length > 0) { 
     elements.map((item) => { 
        $(item).css('font-size', font+'px'); 
      }) 
      obj.map((ele) => {
        if(ele['meta_data']) {
          ele['meta_data']['font_size'] = font; 
        }
        else{
          ele['meta_data'] = {}
          ele['meta_data']['font_size'] = font; 
        }       
      }) 
    }

    this.updateStyling(obj) 
  }
 
} 