import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';
 
@Injectable({
    providedIn: 'root',
})
export class CommunicationService {
 
  
  private userAnnouncedSource = new Subject<string>();
  userAnnounced$ = this.userAnnouncedSource.asObservable();
  private cellUpdatedSource = new Subject<string>();
  cellUpdated$ = this.cellUpdatedSource.asObservable();
  private projectLoadSource = new Subject<string>();
  projectLoaded$ = this.projectLoadSource.asObservable();
  
  announceUser(message: string) {
    this.userAnnouncedSource.next(message);
  }


  announceProjectLoad(message: string) {
    this.projectLoadSource.next(message);
  }
  
 
  // Service message commands
  announceCellRefresh(message: string) {
    this.cellUpdatedSource.next(message);
  }
 
 
}