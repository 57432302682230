import { Injectable } from '@angular/core';
import { Project } from '../models/project.model';
import { User } from '../models/user.model';
import { Column } from '../models/column.model';
import { Cell } from '../models/cell.model';
@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
 
    defaultCols = [{
        'name':'Task Name','type':'string','position':0
    },{
        'name':'Owned By','type':'dropdown','position':1
    },{
        'name':'Start Date','type':'datetime','position':2
    },{
        'name':'End Date','type':'datetime','position':3
    },{
        'name':'Status','type':'dropdown','position':4
    },{
        'name':'Comments','type':'string','position':5
    }];


    createFirstProject(){
        let user:User = new User(1,new Date(),true);
        let project:Project = {id:1,name:'New Project',created_by:user.id,updated_by:user.id,created_at:new Date(),updated_at:new Date()};
        let rows =[];
        for(var i=0;i<25;i++){
          rows.push({id:i+1,position:i,project_id:1})
        }
        let columns:Column[] = [];
        this.defaultCols.forEach((column,i)=>{
          columns.push({id:i+1,position:column.position,name:column.name,project_id:1,column_type:column.type,
            created_by:user.id,updated_by:user.id,created_at:new Date(),updated_at:new Date()})
        })
        let cells:Cell[] =[];
        let id=0;
        rows.forEach((row,i)=>{
          columns.forEach((column,j)=>{
              id++;
            cells.push({id:id,col_position:undefined,row_position:undefined,col_name:column.name,data:null,column_id:column.id,row_id:row.id,
                created_by:user.id,updated_by:user.id,created_at:new Date(),updated_at:new Date(),project_id:project.id,meta_data:{}})
          })
        })
    
        return {user:user,project:project,rows:rows,cells:cells,columns:columns}
        
        
        
      }

      getItems(){
          let obj = localStorage.getItem('__dex_sheet__')
          obj = JSON.parse(obj);
          return obj;
      }

      saveItem(obj:any){
        localStorage.setItem('__dex_sheet__',JSON.stringify(obj));
      }

      removeItem(){
        let obj = localStorage.removeItem('__dex_sheet__')
    }
 
 
}