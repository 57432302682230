import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule }    from '@angular/common/http';
import { FormsModule,ReactiveFormsModule } from '@angular/forms'

import { AppRoutingModule } from './app-routing.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { HotTableModule } from '@handsontable/angular';
import { AngularSplitModule } from 'angular-split';

import { AppComponent } from './app.component';
import { HomeComponent } from './modules/home/home.component';
import { LoginComponent } from './modules/login/login.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { SheetComponent } from './components/sheet/sheet.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    CalendarComponent,
    SheetComponent,
    SidebarComponent,
    HeaderComponent,
    SheetComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FullCalendarModule,
    HotTableModule,
    HttpClientModule,
    AngularSplitModule.forRoot() 
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
