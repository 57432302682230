
export  class Share {
    id?:number;
    user_id?:number;
    project_id:number;
    notes?:string;
    permission:'read'|'write';
    created_at?:Date;
    created_by?:number;
    updated_at?:Date;
    updated_by?:number;
    email:string;
    username?:string;
    name?:string;

}