import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user.model';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    user:User

    constructor(
        private http: HttpClient) {

    }

    setUser(user:User){
        this.user=user;
        if(user && user.picture){
            user.picture2=user.picture.replace('https','http');
        }
    }

    getUser(){
        return this.user;
    }
    
    public loggedInUser(){
        return this.http.get('api/logged_in_user');
    }



}